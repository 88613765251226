import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import Amplify from "@aws-amplify/core";
import Storage from "@aws-amplify/storage";
import { useState } from "react";

Amplify.configure({
  AWSS3: {
    bucket: "sam-app-uploadbucket-qw5wbmuqhg1y", //REQUIRED -  Amazon S3 bucket name
    region: "ap-southeast-2" //OPTIONAL -  Amazon service region
  },
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    //    identityPoolRegion: "XX-XXXX-X",
    identityPoolId: "ap-southeast-2:ef479d40-1bef-4b04-8bf2-bd9fcf847e92",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_8YUWbTG4R",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "v1c62buc5uj3d6t36argmfmg8",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: "localhost",
    //   // OPTIONAL - Cookie path
    //   path: "/",
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: "strict",
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true
    // }

    // OPTIONAL - customized storage object
    //storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    //    authenticationFlowType: "USER_PASSWORD_AUTH",

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    //    clientMetadata: { myCustomKey: "myCustomValue" }
  }
});

function App() {
  const [status, setStatus] = useState("initial");
  const [percentComplete, setPercentComplete] = useState(0);

  async function onChange(e) {
    setStatus("uploading");
    const file = e.target.files[0];
    try {
      await Storage.put(`DataFile.${file.name.split(".").pop()}`, file, {
        progressCallback(progress) {
          setPercentComplete(Math.round((progress.loaded / progress.total) * 100));
        },
        level: "protected",
        contentType: "application/octet-stream" // contentType is optional
      });
      setStatus("complete");
    } catch (error) {
      setStatus("error");
      alert("Error uploading file: " + error);
    }
  }

  const isIntital = status === "initial";
  const isUploading = status === "uploading";
  const isComplete = status === "complete";
  const isError = status === "error";

  return (
    <div>
      <AmplifySignOut />

      <div style={{ padding: "30px" }}>
        <h1>File upload</h1>

        {isIntital && (
          <>
            <p>Please upload the latest member data file</p> <input type="file" onChange={onChange} />{" "}
          </>
        )}
        {isUploading && <p>Uploading please wait, {percentComplete}% </p>}
        {isComplete && <p>Successs, file uploaded</p>}
        {isError && <p>Error, please try refreshing the page or contact us for further help</p>}
      </div>
    </div>
  );
}

export default withAuthenticator(App, {
  usernameAlias: "email"
});
